import React from 'react';
import PropTypes from 'prop-types';

import Layout from 'components/layout.js';

import { Carousel } from 'react-bootstrap';

import ResponsiveEmbed from 'react-responsive-embed';
import './company.css';
import { Timeline, TimelineItem } from 'vertical-timeline-component-for-react';

const site = {};

const decoratorStyles = {
  border: 'solid 1px #2B7CE9',
  color: 'white',
  borderRadius: 2,
  backgroundColor: '#5596ed',
};

const Decorator = props => {
  return (
    <button
      style={decoratorStyles}
      // onClick={}
    >
      Click Me
    </button>
  );
};

Decorator.propTypes = {
  id: PropTypes.string,
};

site.title = process.env.REACT_APP_NAME;

const appName = site.title;
const style = { height: '90vh', width: '90vw' };

const networkProps = {
  style,
  options: {
    layout: {
      randomSeed: 42,
    },
  },
};
const Company = ({ data }) => (
  <Layout>
    {/* <div
          dangerouslySetInnerHTML={
            __html: data.aboutJson.content.childMarkdownRemark.html,
          }
        /> */}
    {/* <Box>  */}

    <main role="main">
      {/* <!-- Main jumbotron for a primary marketing message or call to action --> */}

      <div className="jumbotron page-header">
        <div className="container">
          <h1 className="display-32">About {appName}</h1>
        </div>
      </div>

      <Carousel>
        {/* <Carousel.Item> 
          <Carousel.Caption>
            <h3>Your privacy, redefined.</h3>
            <p>
              No more time wasted on those boring and tricky agreements, unless
              you want to!
            </p>
            <p>
              <Button variant="primary">Learn more</Button>
            </p>
          </Carousel.Caption>
        </Carousel.Item> */}
        {/* <Carousel.Item>
         

          <Carousel.Caption>
            <h3>The world's privacy authority &amp; trust platform.</h3>
            <p>
              We’re simplifying digital privacy for consumers with privacy
              reports and the world's first Personal User Privacy Policy™.
            </p>
          </Carousel.Caption>
        </Carousel.Item> */}
        {/* <Carousel.Item>
        
          <Carousel.Caption>
            <h3>A simpler way to analyze &amp; track user agreements.</h3>
            <p>
              We're solving reducing time it takes to decide what to do about
              those long, boring privacy policies and user agreements.
            </p>
          </Carousel.Caption>
        </Carousel.Item> */}
        {/* <Carousel.Item>
          <Carousel.Caption>
            <h3 className="text-dark">
              A better, smarter &amp; safer future for humanity.
            </h3>
            <p className="text-dark">
              Helping people of all ages and background today ‘enjoy daily
              digital life’.
            </p>
          </Carousel.Caption>
        </Carousel.Item> */}
      </Carousel>
      {/* <!-- Marketing messaging and featurettes */}
      {/* ================================================== --> */}
      {/* <!-- Wrap the rest of the page in another container to center all the content. --> */}

      <section id="what-we-do" className=" d-none">
        <div className="container-fluid">
          <h2 className="section-title mb-2 h1">What we do</h2>
          <p className="text-center text-muted h5">
            Keep online service providers honest and fight for the user.
          </p>
          <div className="row mt-5">
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-block block-1">
                  <h3 className="card-title">Special title</h3>
                  <p className="card-text">
                    With supporting text below as a natural lead-in to
                    additional content.
                  </p>
                  <a
                    href="javascript:void();"
                    title="Read more"
                    className="read-more"
                  >
                    Read more
                    <i className="fa fa-angle-double-right ml-2" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-block block-2">
                  <h3 className="card-title">Special title</h3>
                  <p className="card-text">
                    With supporting text below as a natural lead-in to
                    additional content.
                  </p>
                  <a
                    href="javascript:void();"
                    title="Read more"
                    className="read-more"
                  >
                    Read more
                    <i className="fa fa-angle-double-right ml-2" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-block block-3">
                  <h3 className="card-title">Special title</h3>
                  <p className="card-text">
                    With supporting text below as a natural lead-in to
                    additional content.
                  </p>
                  <a
                    href="javascript:void();"
                    title="Read more"
                    className="read-more"
                  >
                    Read more
                    <i className="fa fa-angle-double-right ml-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-block block-4">
                  <h3 className="card-title">Special title</h3>
                  <p className="card-text">
                    With supporting text below as a natural lead-in to
                    additional content.
                  </p>
                  <a
                    href="javascript:void();"
                    title="Read more"
                    className="read-more"
                  >
                    Read more
                    <i className="fa fa-angle-double-right ml-2" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-block block-5">
                  <h3 className="card-title">Special title</h3>
                  <p className="card-text">
                    With supporting text below as a natural lead-in to
                    additional content.
                  </p>
                  <a
                    href="javascript:void();"
                    title="Read more"
                    className="read-more"
                  >
                    Read more
                    <i className="fa fa-angle-double-right ml-2" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4 col-lg-4 col-xl-4">
              <div className="card">
                <div className="card-block block-6">
                  <h3 className="card-title">Special title</h3>
                  <p className="card-text">
                    With supporting text below as a natural lead-in to
                    additional content.
                  </p>
                  <a
                    href="javascript:void();"
                    title="Read more"
                    className="read-more"
                  >
                    Read more
                    <i className="fa fa-angle-double-right ml-2" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Tabs --> */}
      {/* <section id="tabs">
<div className="container">
  <!-- <h6 className="section-title h1">Tabs</h6> -->
  <div className="row">
      <div className="col-xs-12 ">
          <nav>
              <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                  <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Home</a>
                  <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</a>
                  <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</a>
                  <a className="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false">About</a>
              </div>
          </nav>
          <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
              <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
              <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
              <div className="tab-pane fade" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
          </div>
      
      </div>
  </div>
</div>
</section> */}
      {/* <!-- ./Tabs --> */}

      <div className="container">
        {/* <br />
        <br /> */}
        {/* <h1 className=" ">About {appName}</h1> */}
        <h3>Who We Are</h3>
        <p>
          A company founded to helping organizations create innovative digital solutions.
        </p>
        <h3>Our Mission</h3>
        <h4>To create a better, smarter &amp; safer future for humanity.</h4>
        <p>
          <h5>Mission statement</h5>
          <p>
            {' '}
            Our mission is to understand the universe &amp; organize it’s
            resources to make a better, smarter and safer future for humanity.
          </p>
        </p>
        <p>
          <h4>Our V​ision</h4>
          Help humanity achieve progress through breakthrough solutions to
          create value and impact for a better, smarter and safer world. Bring
          inspiration, imagination, innovation and augmented intelligence to
          every person in the world to help us better understand the universe
          and ourselves.
        </p>
        <hr />
        <h3>What is {appName}?</h3>
        <p>
          {appName} was formed by leaders of the technology industry, because
          they have been frustrated by the quality, service, and high costs and
          stress that people, customers, organizations, investors, employees and
          their families have experienced in the U.S. technology system and
          markets globally that use technology as a tool to work, live, play,
          create, invent and solve challenges into the future. We believe that
          we can do better, and in taking this step to form this new
          organization, we have committed to being a part of the solution.
        </p>{' '}
        <p>
          <h3>What We Do</h3>
          <h4>
            We’re a human, data, science &amp; technology driven company.{' '}
          </h4>
          Our mission is to invent and launch “breakthrough” solutions that we
          hope could someday make the world a radically better place. We have a
          long way to go before we can fulfill this mission, so today it’s
          really a vision starting with some technologies we want to bring.
        </p>
        <p>
          The goal of {appName} Corp is to develop technologies that could
          “someday make the world a radically better place.” The organization
          follows a three-part pillars for their projects that starts with
          identifying a “huge problem” and then providing a “radical solution”
          that could be implemented using a “breakthrough technology.”
        </p>
        {/* <hr />
        <h3>A Word from our Founder and CEO</h3>
        <p>
          "As an engineer, inventor, advisor and serial entrepreneur, I've
          devoted my career to caring for my stakeholders, customers and users
          and working to make the entire technology world and the 'grid' better.{' '}
        </p>
        <p>
          I believe all people deserve quality technology solutions and services
          that are intelligent, useful, time saving but also private, safe,
          trusted, affordable and accessible.
        </p>
        <p>
          On that note, I decided recently, that I would pause some things I was
          considering doing to spend the next couple of years to help solve some
          key problems by starting to put an end to the lack of transparency
          over the risks in the Internet to help change things for the better.
          I've built and constructed a variety of inventions from a few days of
          inspiration while advising global audiences on privacy and policy
          issues. One of them is a sort of 'T.R.O.N.' SENTINAL AI SYSTEM with
          algorithms and adaptive capabilities thanks to AI+human combination
          that tracks the risks of every data collecting technology, product and
          service in the world. "
         
        </p> */}
      </div>

      {/* <!-- Marketing messaging and featurettes */}
      {/* ================================================== --> */}
      {/* <!-- Wrap the rest of the page in another container to center all the content. --> */}

      <div className="container marketing">
        {/* <!-- Tabs --> */}
        {/* <section id="tabs">
<div className="container">
  <!-- <h6 className="section-title h1">Tabs</h6> -->
  <div className="row">
      <div className="col-xs-12 ">
          <nav>
              <div className="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
                  <a className="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home" role="tab" aria-controls="nav-home" aria-selected="true">Home</a>
                  <a className="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile" role="tab" aria-controls="nav-profile" aria-selected="false">Profile</a>
                  <a className="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact" role="tab" aria-controls="nav-contact" aria-selected="false">Contact</a>
                  <a className="nav-item nav-link" id="nav-about-tab" data-toggle="tab" href="#nav-about" role="tab" aria-controls="nav-about" aria-selected="false">About</a>
              </div>
          </nav>
          <div className="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
              <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
              <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
              <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
              <div className="tab-pane fade" id="nav-about" role="tabpanel" aria-labelledby="nav-about-tab">
                  Et et consectetur ipsum labore excepteur est proident excepteur ad velit occaecat qui minim occaecat veniam. Fugiat veniam incididunt anim aliqua enim pariatur veniam sunt est aute sit dolor anim. Velit non irure adipisicing aliqua ullamco irure incididunt irure non esse consectetur nostrud minim non minim occaecat. Amet duis do nisi duis veniam non est eiusmod tempor incididunt tempor dolor ipsum in qui sit. Exercitation mollit sit culpa nisi culpa non adipisicing reprehenderit do dolore. Duis reprehenderit occaecat anim ullamco ad duis occaecat ex.
              </div>
          </div>
      
      </div>
  </div>
</div>
</section> */}
        {/* <!-- ./Tabs --> */}
      </div>

      {/* <!-- /.container --> */}
      {/* <Team /> */}
      <div className="container">
        <h1>History</h1>

        
        <h3>History of How {site.title} Began</h3>
          <p>
            Prior work, IP development, engineering, R&amp;D, product
            development &amp; market research led to a new Company being founded
            in 2017. {site.title} emerged from a new important problem
            identified that became top priority focus for the team around May
            2018. On April 4, 2018, Omar Uddin, CEO was invited by a reporter
            from Voice of America TV, a 75 yr old US-government sponsored news
            media channel, to speak to International audiences about Facebook
            &amp; Cambridge Analytica privacy breach issues among other threats
            on such sites. Also a key issue stated by Uddin was “consumers must
            be aware of the cost of using websites that force users to consent
            to complicated agreements that they never read and something must be
            done about it as this doesn’t tell them how safe, private or secure
            the service is”. This is how the {site.title} vision, idea &amp;
            roadmap emerged. Within a few days, a complete vision &amp; concept
            was developed &amp; customer development initiated with interviews
            with people of various age ranges &amp; backgrounds to learn about
            people’s concerns of privacy. Problems were discovered that were
            prioritized based on needs and possible solutions. Product roadmaps
            and prototypes were developed &amp; vetted with early adopters, a
            team of experts &amp; advisors as well as early interested
            investors.
          </p> 
          {/* <TimelineVertical /> */}

        <TimelineVertical />
      </div>
      {/* <hr/> */}
      {/* <Timeline lineColor={'#ddd'}>
        <TimelineItem
          key="001"
          dateText="11/2010 – Present"
          style={{ color: '#e86971' }}
        >
          <h3>Title, Company</h3>
          <h4>Subtitle</h4>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
        </TimelineItem>
        <TimelineItem
          key="002"
          dateText="04/2009 – 11/2010"
          dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
          bodyContainerStyle={{
            background: '#ddd',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
          }}
        >
          <h3 style={{ color: '#61b8ff' }}>Title, Company</h3>
          <h4 style={{ color: '#61b8ff' }}>Subtitle</h4>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
        </TimelineItem>
        <TimelineItem
          key="003"
          dateComponent={
            <div
              style={{
                display: 'block',
                float: 'left',
                padding: '10px',
                background: 'rgb(150, 150, 150)',
                color: '#fff',
              }}
            >
              11/2008 – 04/2009
            </div>
          }
        >
          <h3>Title, Company</h3>
          <h4>Subtitle</h4>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
        </TimelineItem>
        <TimelineItem
          key="004"
          dateText="08/2008 – 11/2008"
          dateInnerStyle={{ background: '#76bb7f' }}
        >
          <h3>Title, Company</h3>
          <h4>Subtitle</h4>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
          <p>
            Est incididunt sint eu minim dolore mollit velit velit commodo ex
            nulla exercitation. Veniam velit adipisicing anim excepteur nostrud
            magna nostrud aliqua dolor. Sunt aute est duis ut nulla officia
            irure reprehenderit laborum fugiat dolore in elit. Adipisicing do
            qui duis Lorem est.
          </p>
        </TimelineItem>
      </Timeline> */}
    </main>

    {/* <div className="row">
						<div className="col-sm-12 text-center">
							<img alt="Affiliate" src="/theme/img/l1.png">
							<img alt="Affiliate" src="/theme/img/l2.png">
							<img alt="Affiliate" src="/theme/img/l3.png">
							<img alt="Affiliate" src="/theme/img/l4.png">
							<img alt="Affiliate" src="/theme/img/l5.png">
						</div> */}
    {/* </Box>   */}

    {/* <div className="container">
  <div className="row">
    <div className="col-md-6">
      <h3 className="uppercase mb0">
        Company
      </h3>
    </div>
    <div className="col-md-6 text-right">
      <ol className="breadcrumb breadcrumb-2"><li><a href="/" className="home-link" rel="home">Home</a></li><li className="active">Company</li></ol>
    </div>
  </div>
</div> */}

  </Layout>
);

Company.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Company;

// const query = graphql`
//   query CompanyQuery {
//     companyJson {
//       title
//       content {
//         childMarkdownRemark {
//           html
//         }
//       }
//     }
//   }
// `;

// import DemoTabs from '../components/slides/tabs';

//In fact, hardware and software had defects that he had known about since he started programming computers professionally self taught in his teens on PCs, Linux, mobile, virtual reality and even supercomputers.

const TimelineVertical = () => (
  <Timeline lineColor={'#000'}>
 
<TimelineItem
      key="003b"
      dateText="April 15, 2018"
      dateInnerStyle={{ background: '#000' }}
        dateComponent={
        <div
        style={{
          display: 'block',
          float: 'left',
          padding: '10px',
          background: '#000',
          color: '#fff',
        }}
        >
         20+ Years of R&amp;D
        </div>
      }

      // dateText="2017-2019"
      // dateComponent={
      //   <div
      //     style={{
      //       display: 'block',
      //       float: 'left',
      //       padding: '10px',
      //       background: 'rgb(150, 150, 150)',
      //       color: '#fff',
      //     }}
      //   >
      //     2017-2018
      //   </div>
      // }
    >
      <h3>R&amp;D</h3> 
{/*         
      <p> 
      </p> 
        <p>
          After systematically testing several ideas in areas like education,
          health, security, privacy, personal computing, social networking,
          travel, business, finance, and premium services, the Company and the founding team will build
          enduring organizations around the winners.
        </p><br /> */}
    </TimelineItem>
    {/* <TimelineItem
      key="001"
      dateText="2010 - Launched first idea"
      style={{ color: '#000' }}
    >
      <h3>First Idea</h3>
      <h4>Domain, brand, website owner threat monitoring</h4>
      <p>
        While protecting and trusted by high valued assets such as multi million
        dollar web domains for businesses, high end needs clients, celebrities,
        high net worth individuals, this idea was generated at a time when ad
        networks were being infected. Major sites were being blacklisted by
        StopBadWare.org sponsored by Google. We needed better tools and systems
        to get early warning indications of the threats.
         In addition, it was observed while working to build custom software for enterprise IT solutions for federal agencies and other organizations certain standards had to be passed as defined by private pen testing tools, now acquired by IBM, Omar noticed that engineers and developers weren't doing enough to ensure privacy and security. It was almost never being baked in to the hardware or software.  
      </p>
      <p>
    BadWareMonitor.com - Idea was simple just wanted a simple monitoring solution if a website or domain had a threat.
    </p>
    <p>
    Instead turned into a cybersecurity, risk and threat trend monitoring watchdog AI bot for Twitter <a href="https://twitter.com/badwaremonitor/">@BadWareMonitor</a>.
    </p>  
    </TimelineItem> */}
    {/* <TimelineItem
    key="002"
    dateText="04/2009 – 11/2010"
    dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
    bodyContainerStyle={{
      background: '#ddd',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
    }}
  >
    <h3 style={{ color: '#61b8ff' }}>Title, Company</h3>
    <h4 style={{ color: '#61b8ff' }}>Subtitle</h4>
    <p>
      Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
      exercitation. Veniam velit adipisicing anim excepteur nostrud magna
      nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
      reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
      est.
    </p>
    <p>
      Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
      exercitation. Veniam velit adipisicing anim excepteur nostrud magna
      nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
      reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
      est.
    </p>
  </TimelineItem> */}
    {/*  
 <TimelineItem
    key="003"
    dateComponent={(
      <div
        style={{
          display: 'block',
          float: 'left',
          padding: '10px',
          background: 'rgb(150, 150, 150)',
          color: '#fff',
        }}
      >
        April 15, 2014
      </div>
    )}
  >
    <h3>Advised top US defense contractors of threat of not applying custom end to end encryption to all cloud services using AWS GovCloud.</h3>
    <br/>   <br/> <h4>While working as a SME consulting advisor designing and implementing proprietary tamper-detecting, auditable military asset supply chain security technology</h4>
    <p> <br/>
      <blockquote>Omar - why should we use an extra layer of encryption? DUH - SSL is not secure - the same week Google tried to recruit Omar to join Senior Management team and Heartbleed was revealed by Google security team.</blockquote>
    </p>
    <p>
      
    </p>
  </TimelineItem> */}
    <TimelineItem
      key="003"
      dateComponent={
        <div
          style={{
            display: 'block',
            float: 'left',
            padding: '10px',
            background: '#000',
            color: '#fff',
          }}
        >
          April 15, 2018
        </div>
      }
    >
      <h3>Founder interviewed by Voice of America TV</h3> <br />
      <h4>Discussing Cambridge Analytica and Facebook privacy woes</h4> <br />
      <p>
        <h5>
          <blockquote>
            <i>"People don't have time nor expertise in reviewing privacy policies plus user agreements do not give you any confidence of privacy or cybersecurity practices." - Omar Uddin, Founder & CEO</i>
          </blockquote>
        </h5>
      </p>
      <p>
        {/* <iframe width="560" height="315" src="https://www.youtube.com/embed/a7rHRhwDxT0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"></iframe> */}

        <ResponsiveEmbed
          src={`https://www.youtube.com/embed/a7rHRhwDxT0`}
          allowFullScreen
        />
      </p>
    </TimelineItem>

    <TimelineItem
      key="004"
      dateText="May 2018"
      dateInnerStyle={{ background: '#76bb7f' }}
    >
      <h3>DataPolicyTrust</h3> <br />
      <h4>The Vision was born.</h4>
      {/* 
        <h3>Who We Are</h3> A company founded in 2017 focused on creating a
        better, smarter &amp; safer future for humanity.
        <h3>Our Story</h3>
        Our team has been previously been working to help improve data security and data privacy across
        private and public sectors and has also developed multiple solutions to
        help increase visibility &amp; intelligence into risks &amp; threats
        into business &amp; personal data. */}
      <p>
        This solution was born out of a growing need and demand for more
        transparency around tech world policies, business model use of data,
        protections, practices, and security breaches. In addition as a measure
        to mitigate far reaching and expensive laws and new regulation by using
        AI and human ingenuity and a new culture from tech world to self
        regulate the tech world and marketplaces as well as create a Light Web -
        the safe part of the Internet that will be trusted and constantly be a
        pulse or DEFCON alert status for each product, site, org and ultimately
        every person's risk exposure and the world in the end.
      </p>{' '}
      <hr />
      <p>
        "All the solutions we found were not what we were needing and not saving
        us time or giving us all the data we needed over time and at a moments
        notice. Our success is inextricably linked to developer success, and
        that ethos guides everything we do," said Omar Uddin, CEO of Enlightened
        Corporation. "From our free Online Services and features, our goal is to
        help developers, and to equip them to give users even better more
        trusted and private experiences."
      </p>
      <p>
        Powered by Founder's vision and system codename "T.R.O.N." (TECHNOLOGY
        REALTIME OPERATING NETWORK) SENTINEL AI - a global security watch dog
        monitoring service and system powered by human and AI infused
        intelligence powered by data to help 'protect the grid' and to help be a
        sentinel for users in our growing, connected world of technology, AI and
        data processing devices.
      </p>
    </TimelineItem>
    <TimelineItem
      key="004"
      dateText="2018 - 2019"
      dateInnerStyle={{ background: '#76bb7f' }}
    >
      <h3>DataPolicyTrust Discovery, R&amp;D and Market Research</h3>{' '}
      <br />
      {/* <h4>The {site.title} Platform Vision Private Beta in R&amp;D.</h4> */}
    </TimelineItem>
    {/* <TimelineItem
    key="004"
    dateText="FUTURE"
    dateInnerStyle={{ background: '#76bb7f' }}
  >
    <h3>{site.title} Early Access - Public Beta</h3> <br/> 
       
  </TimelineItem> */}
  
    {/* <TimelineItem
      key="001"
      dateText="2010 - Launched first idea"
      style={{ color: '#000' }}
    >
      <h3>First Idea</h3>
      <h4>Domain, brand, website owner threat monitoring</h4>
      <p>
        While protecting and trusted by high valued assets such as multi million
        dollar web domains for businesses, high end needs clients, celebrities,
        high net worth individuals, this idea was generated at a time when ad
        networks were being infected. Major sites were being blacklisted by
        StopBadWare.org sponsored by Google. We needed better tools and systems
        to get early warning indications of the threats.
         In addition, it was observed while working to build custom software for enterprise IT solutions for federal agencies and other organizations certain standards had to be passed as defined by private pen testing tools, now acquired by IBM, Omar noticed that engineers and developers weren't doing enough to ensure privacy and security. It was almost never being baked in to the hardware or software.  
      </p>
      <p>
    BadWareMonitor.com - Idea was simple just wanted a simple monitoring solution if a website or domain had a threat.
    </p>
    <p>
    Instead turned into a cybersecurity, risk and threat trend monitoring watchdog AI bot for Twitter <a href="https://twitter.com/badwaremonitor/">@BadWareMonitor</a>.
    </p>  
    </TimelineItem> */}
    {/* <TimelineItem
    key="002"
    dateText="04/2009 – 11/2010"
    dateInnerStyle={{ background: '#61b8ff', color: '#000' }}
    bodyContainerStyle={{
      background: '#ddd',
      padding: '20px',
      borderRadius: '8px',
      boxShadow: '0.5rem 0.5rem 2rem 0 rgba(0, 0, 0, 0.2)',
    }}
  >
    <h3 style={{ color: '#61b8ff' }}>Title, Company</h3>
    <h4 style={{ color: '#61b8ff' }}>Subtitle</h4>
    <p>
      Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
      exercitation. Veniam velit adipisicing anim excepteur nostrud magna
      nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
      reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
      est.
    </p>
    <p>
      Est incididunt sint eu minim dolore mollit velit velit commodo ex nulla
      exercitation. Veniam velit adipisicing anim excepteur nostrud magna
      nostrud aliqua dolor. Sunt aute est duis ut nulla officia irure
      reprehenderit laborum fugiat dolore in elit. Adipisicing do qui duis Lorem
      est.
    </p>
  </TimelineItem> */}
    {/*  
 <TimelineItem
    key="003"
    dateComponent={(
      <div
        style={{
          display: 'block',
          float: 'left',
          padding: '10px',
          background: 'rgb(150, 150, 150)',
          color: '#fff',
        }}
      >
        April 15, 2014
      </div>
    )}
  >
    <h3>Advised top US defense contractors of threat of not applying custom end to end encryption to all cloud services using AWS GovCloud.</h3>
    <br/>   <br/> <h4>While working as a SME consulting advisor designing and implementing proprietary tamper-detecting, auditable military asset supply chain security technology</h4>
    <p> <br/>
      <blockquote>Omar - why should we use an extra layer of encryption? DUH - SSL is not secure - the same week Google tried to recruit Omar to join Senior Management team and Heartbleed was revealed by Google security team.</blockquote>
    </p>
    <p>
      
    </p>
  </TimelineItem> */}
     

    {/* <TimelineItem
      key="003"
      dateComponent={
        <div
          style={{
            display: 'block',
            float: 'left',
            padding: '10px',
            background: 'rgb(150, 150, 150)',
            color: '#fff',
          }}
        >
          October 2017
        </div>
      } 
    >
      <h3>{appName} FORMED</h3> <br />
    </TimelineItem> */}
      <TimelineItem
    key="004"
    dateText="Q1 2020" 
    dateInnerStyle={{ background: '#76bb7f' }}
  >
    <h3>DataPolicyTrust Early Access - Public Beta</h3> <br/> 
       
  </TimelineItem>  
    <TimelineItem
      key="004"
      dateText="Present"
      dateInnerStyle={{ background: '#76bb7f' }}
    >
      <h3>DataPolicyTrust Private Beta Launched!</h3> <br />
      {/* <h4>The {site.title} Platform Vision Private Beta in R&amp;D.</h4> */}
    </TimelineItem>
   
  </Timeline>
);

const Team = () => (
  <div className="container">
    <h5 className="section-title2 h1b">OUR TEAM</h5>
    <section id="team" className="pb-52 d-none2">
      <div className="container">
        <div className="row">
          <div className="col-xs-12 col-sm-6 col-md-4">
            <div
              className="image-flip"
              ontouchstart="this.classList.toggle('hover');"
            >
              <div className="mainflip">
                <div className="frontside">
                  <div className="card">
                    <div className="card-body text-center">
                      <p>
                        <img
                          className=" img-fluid"
                          src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_01.png"
                          alt="card image"
                        />
                      </p>
                      <h4 className="card-title">Omar Uddin</h4>
                      <p className="card-text">CEO &amp; Founder.</p>
                      <a href="#" className="btn btn-primary btn-sm">
                        <i className="fa fa-plus" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="backside">
                  <div className="card">
                    <div className="card-body text-center mt-4">
                      <h4 className="card-title">Omar Uddin</h4>
                      <p className="card-text">
                        Been developing since 14 and in startups since 20.
                        Visionary behind the {appName} vision &amp; roadmap to
                        improve life for humanity and the world.
                      </p>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-skype" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-google" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 d-none2">
            <div
              className="image-flip"
              ontouchstart="this.classList.toggle('hover');"
            >
              <div className="mainflip">
                <div className="frontside">
                  <div className="card">
                    <div className="card-body text-center">
                      <p>
                        <img
                          className=" img-fluid"
                          src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_02.png"
                          alt="card image"
                        />
                      </p>
                      <h4 className="card-title">Sunlimetech</h4>
                      <p className="card-text">
                        This is basic card with image on top, title, description
                        and button.
                      </p>
                      <a href="#" className="btn btn-primary btn-sm">
                        <i className="fa fa-plus" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="backside">
                  <div className="card">
                    <div className="card-body text-center mt-4">
                      <h4 className="card-title">Sunlimetech</h4>
                      <p className="card-text">
                        This is basic card with image on top, title, description
                        and button.This is basic card with image on top, title,
                        description and button.This is basic card with image on
                        top, title, description and button.
                      </p>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-skype" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-google" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 d-none2">
            <div
              className="image-flip"
              ontouchstart="this.classList.toggle('hover');"
            >
              <div className="mainflip">
                <div className="frontside">
                  <div className="card">
                    <div className="card-body text-center">
                      <p>
                        <img
                          className=" img-fluid"
                          src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_03.png"
                          alt="card image"
                        />
                      </p>
                      <h4 className="card-title">Sunlimetech</h4>
                      <p className="card-text">
                        This is basic card with image on top, title, description
                        and button.
                      </p>
                      <a href="#" className="btn btn-primary btn-sm">
                        <i className="fa fa-plus" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="backside">
                  <div className="card">
                    <div className="card-body text-center mt-4">
                      <h4 className="card-title">Sunlimetech</h4>
                      <p className="card-text">
                        This is basic card with image on top, title, description
                        and button.This is basic card with image on top, title,
                        description and button.This is basic card with image on
                        top, title, description and button.
                      </p>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-skype" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-google" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 d-none2">
            <div
              className="image-flip"
              ontouchstart="this.classList.toggle('hover');"
            >
              <div className="mainflip">
                <div className="frontside">
                  <div className="card">
                    <div className="card-body text-center">
                      <p>
                        <img
                          className=" img-fluid"
                          src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_04.jpg"
                          alt="card image"
                        />
                      </p>
                      <h4 className="card-title">Sunlimetech</h4>
                      <p className="card-text">
                        This is basic card with image on top, title, description
                        and button.
                      </p>
                      <a href="#" className="btn btn-primary btn-sm">
                        <i className="fa fa-plus" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="backside">
                  <div className="card">
                    <div className="card-body text-center mt-4">
                      <h4 className="card-title">Sunlimetech</h4>
                      <p className="card-text">
                        This is basic card with image on top, title, description
                        and button.This is basic card with image on top, title,
                        description and button.This is basic card with image on
                        top, title, description and button.
                      </p>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-skype" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-google" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 d-none2">
            <div
              className="image-flip"
              ontouchstart="this.classList.toggle('hover');"
            >
              <div className="mainflip">
                <div className="frontside">
                  <div className="card">
                    <div className="card-body text-center">
                      <p>
                        <img
                          className=" img-fluid"
                          src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_05.png"
                          alt="card image"
                        />
                      </p>
                      <h4 className="card-title">Sunlimetech</h4>
                      <p className="card-text">
                        This is basic card with image on top, title, description
                        and button.
                      </p>
                      <a href="#" className="btn btn-primary btn-sm">
                        <i className="fa fa-plus" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="backside">
                  <div className="card">
                    <div className="card-body text-center mt-4">
                      <h4 className="card-title">Sunlimetech</h4>
                      <p className="card-text">
                        This is basic card with image on top, title, description
                        and button.This is basic card with image on top, title,
                        description and button.This is basic card with image on
                        top, title, description and button.
                      </p>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-skype" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-google" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-6 col-md-4 d-none2">
            <div
              className="image-flip"
              ontouchstart="this.classList.toggle('hover');"
            >
              <div className="mainflip">
                <div className="frontside">
                  <div className="card">
                    <div className="card-body text-center">
                      <p>
                        <img
                          className=" img-fluid"
                          src="https://sunlimetech.com/portfolio/boot4menu/assets/imgs/team/img_06.jpg"
                          alt="card image"
                        />
                      </p>
                      <h4 className="card-title">Sunlimetech</h4>
                      <p className="card-text">
                        This is basic card with image on top, title, description
                        and button.
                      </p>
                      <a href="#" className="btn btn-primary btn-sm">
                        <i className="fa fa-plus" />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="backside">
                  <div className="card">
                    <div className="card-body text-center mt-4">
                      <h4 className="card-title">Sunlimetech</h4>
                      <p className="card-text">
                        This is basic card with image on top, title, description
                        and button.This is basic card with image on top, title,
                        description and button.This is basic card with image on
                        top, title, description and button.
                      </p>
                      <ul className="list-inline">
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-facebook" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-twitter" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-skype" />
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a
                            className="social-icon text-xs-center"
                            target="_blank"
                            href="#"
                          >
                            <i className="fa fa-google" />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
);
